//Imports
require('./src/jquery-mTab-min');
var tab_active = require('./src/account__section-tabs.js');
var carousle_active = require('./src/carousel-active.js');
var history_slider = require('./src/history_slider.js');
require('./src/equalheight.js');
require('./src/feature-grid.js');
var intabvertical = require('./src/inTabvertical.js');

//JQuery Load on DOM Content loading
document.addEventListener("DOMContentLoaded", () => {
    tab_active.init();
    carousle_active.init();
    history_slider.init();
    //history_slider_fn.init();
});
$(document).ready(function () {
    intabvertical.init();
});

$('.tabbing').mTab({
    navigation: ".tabNav"
    , container: ".tabContainer"
    , activeTab: 1
    , activeClass: "active"
    , scrollOffset: false
    , accordScreen: 768
    , toggleClose: true
    , animation: false
    , openWithUrl: true
    , callbackonclick: function () { }
    , callback: function () { }
});

$(document).ready(function () {
    $('#hero_banner_slider_indicators').carousel();
    if ($('.history_slider .owl-stage')) {
        $('.history_slider .owl-stage').css('height', $('.history_slider .owl-stage').outerHeight());
    }
    // Click event on Advisor component
    $(".column_advisor .switch-side span").click(function () {
        $(this).css("display", "none");
        $(".column_advisor .switch-side .input-search").css("display", "block");
    });

    // header megamenu up-down arrow

    $(".navbar-nav .nav-item").on('touchstart mouseenter', function () {
        if (!("ontouchstart" in document.documentElement)) {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
            } else {
                $(this).addClass("active")
            }
        }
    });
    $(".navbar-nav .nav-item").on('touchmove mouseleave', function () {
        if (!("ontouchstart" in document.documentElement)) {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
            } else {
                $(this).addClass("active")
            }
        }
    });

    //Check for login Popup Cookies
    if (document.getElementById('IsHomePage').value == "TRUE") {
        checkCookie();
    }

    //Change the Tel : number
    $('a[href^="#tel:"]').each(function () {
        var oldUrl = $(this).attr("href"); // Get current url
        var newUrl = oldUrl.replace("#tel:", "tel:"); // Create new url
        $(this).attr("href", newUrl); // Set herf value
    });

    $('a[href^="#Tel:"]').each(function () {
        var oldUrl = $(this).attr("href"); // Get current url
        var newUrl = oldUrl.replace("#Tel:", "tel:"); // Create new url
        $(this).attr("href", newUrl); // Set herf value
    });
});

//validation on input tag

$('#input-group .btn').click(function () {
    if (!$('#input-group .form-control ').val()) {
        $('#input-group .form-control ').addClass("warning");
    }
    else {
        $('#input-group .form-control ').removeClass("warning");
    }
});

//Login popup Tab update
$('.modal-toggle').click(function (e) {
    var tab = e.target.hash;
    $('#myTab a[href="' + tab + '"]').tab('show');
});


/////////////-----------Cookies ----------------//////////////////////////

function openLoginPopup() {
    try {
        $('#LoginPopup').modal('show');
    } catch (e) {
        // alert(e.message);
    }
}

//  Set the Cookie
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

// Get the Cookie
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Check if Cookie exists
function checkCookie() {
    // Get the cookie called "visited"
    var visited = getCookie("IsLoginDisplayed");

    // If it exists, print the Cookie to the Console
    if (visited == "true") {
        console.log(document.cookie);
    }
    else {
        // If not, add the class 'is-visible' to my modal called 'test'
        // and create a the cookie "visited=true" expiring in 1 days.
        //check here vishal sen

        //Open Model
        setCookie("IsLoginDisplayed", "true", 1);
    }
}



//Finding external links and open modal for external links - 

$(document).ready(function () {
    var hostArray = $("#externalSiteWarningIgnoreDomain").attr("value").split(",");
    var clickedEleIs;
    $(document).on('click', 'a:not(.external-link-redirecter)', function (e) {
        clickedEleIs = closest(e.target, 'a');
        var clickedEleHref = clickedEleIs.hostname.toLowerCase();
        hostArray = hostArray.map(function (x) { return x.toLowerCase(); })
        if (clickedEleHref != "") {
            if (hostArray.indexOf(clickedEleHref) === -1) {

                $(".external-link-modal").show();

                return false;
            }
        }
        else {
            return true;
        }
    });

    $('.external-link-redirecter').on('click', function () {
        this.href = clickedEleIs.href;
        $('.external-link-modal').hide();
        return true;
    });

    function closest(el, selector) {
        var matchesFn;

        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        })

        var parent;

        // traverse parents
        while (el) {
            if (el.nodeName.toLowerCase() === selector) {
                return el;
            }
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }
});

//External link modal close ------>


$('.external-link-modal .close').on('click', function () {
    $('.external-link-modal').hide();
});



//$('.external-link-modal .stay-link').on('click', function () {
//    $('.external-link-modal').hide();
//});

