(function () {

    var intabvertical = {


        init: function () {
            var verticaltabfind = $('.vartical_tab').find(".nav-pills a")[0];
            $(verticaltabfind).addClass("active");

        }

    }

    module.exports = intabvertical;

})();