

var equalHeight = function (element) {
    var currentTallest = 0, //Tallest Blog Item
        currentRowStart = 0, //Current Blog item Row
        rowBlogItems = new Array(), //Array of Blog Items
        $this,
        _itemtTopPosition = 0; //Top postion of the blog item

    //goes through each blog item
    $(element).each(function () {
        $this = $(this);
        //grabs the top postions of each blog item
        _itemtTopPosition = $this.position().top;
        if (currentRowStart != _itemtTopPosition) {
            // we just came to a new row.  Set all the heights on the completed row
            for (currentBlogItem = 0; currentBlogItem < rowBlogItems.length; currentBlogItem++) {
                rowBlogItems[currentBlogItem].height(currentTallest);
            }
            // set the variables for the new row
            rowBlogItems.length = 0; // empty the array
            currentRowStart = _itemtTopPosition;
            currentTallest = getInnerHeight($this);
            rowBlogItems.push($this);
        } else {
            rowBlogItems.push($this);
            currentTallest = (currentTallest < getInnerHeight($this)) ? (getInnerHeight($this)) : (currentTallest);
        }

        // do the last row

        for (currentBlogItem = 0; currentBlogItem < rowBlogItems.length; currentBlogItem++) {
            if (window.outerWidth > 767) {
                rowBlogItems[currentBlogItem].height(currentTallest);
            } else {
                rowBlogItems[currentBlogItem].height('auto');
            }
        }
    });

} // end modal

function getInnerHeight(obj) {
    var totalHeight = 0;
    $(obj).children().each(function () {
        if ($(this).css('display') != 'none') {
            totalHeight = totalHeight + $(this).outerHeight(true);
        }
    });
    return totalHeight;
}

var windowsize = $(window).width();


$(document).ready(function () {
    equalHeight("#Gallery .carousel-item");
});


$(window).resize(function () {

    equalHeight("#Gallery .carousel-item");


});



$(document).ready(function () {
    equalHeight(".column_3_img .content_upper ");

});


$(window).resize(function () {

    equalHeight(".column_3_img .content_upper ");


});


$(document).ready(function () {
    equalHeight(".card_section_content .card-text ");

});


$(window).resize(function () {

    equalHeight(".card_section_content .card-text ");


});




