(function () {

    var carousel_active_class_add = {


        init: function () {
            var slider_hero_find = $(".carousel__section");
            slider_hero_find.find(".carousel-indicators > li:first-child").addClass("active"); 
            slider_hero_find.find(".carousel-inner > .carousel-item:first-child").addClass("active"); 
            var slider_gellary_find = $(".gallery__section");
            slider_gellary_find.find(".carousel-inner > .carousel-item:first-child").addClass("active"); 
        }   
      
    }

    module.exports = carousel_active_class_add;

})();

