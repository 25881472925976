

(function () {

    var history_slider = {


        init : function () {
           //History Slider navigation Code https://codepen.io/creotip/pen/lLwfh
            var owl = $('.history_slider .owl-carousel');

            owl.owlCarousel({
                dotsData: true,
                items: 1,
                loop: false,
                center: true,
                nav: true,
                URLhashListener: true,
                autoplayHoverPause: true,
                startPosition: 'URLHash',
                responsiveClass: true,
                responsive: {
                    1023: {
                        stagePadding: 200,
                        margin: 20,
                    },
                    1390: {
                        stagePadding: 400,
                        margin: 30,
                    }
                }
            })
            
            $('.owl-dot').click(function () {
                owl.trigger('to.owl.carousel', [$(this).index(), 1000]);
            })

        }   
       
    }

    module.exports = history_slider;

})();


