/*jshint esversion: 6 */
var markers = [];
var searchbox;
var mile = parseInt($('#sl_miles').val());
var searchedPlace;
var $searchField = $("#pac-input");
var map = null;
if (typeof google === 'object' && typeof google.maps === 'object') {
    map = google.maps.Map;
} 
var objArray = [];
var selectbox = $('.choose-location').html();
var hitEnter = false;
var input = document.getElementById('pac-input');

function initSearch() {

    var lastLocation = JSON.parse(localStorage.getItem("lastLocation"));
    var locationLatLong = JSON.parse(localStorage.getItem("locationLatLong"));
    if (lastLocation != null && lastLocation.isBackButtonClicked === true) {
        $searchField.val(lastLocation.location);
        getLocationData(lastLocation.location, locationLatLong.lat, locationLatLong.longi, mile, false);

        $(window).scrollTop(lastLocation.topPosition);
        localStorage.setItem("lastLocation", null);

    } else {
        $searchField.val("");
    }

    if (input) {
        initMap();
    }

    if ($searchField.length > 0) {
        let autosuggestUrl = '/api/location/locationfinder/Getlocations';
        $.ajax({
            method: "GET",
            dataType: 'json',
            contentType: "application/json",
            url: autosuggestUrl
        })
            .done(function (data) {
                var response = data;
                if (response) {
                    $.each(response,
                        function (key, value) {
                            objArray.push(value);
                        });
                }
            });
    }
}

function getLocationData(keyword, lat, longi, mile, hideMap) {
    $.ajax({
        method: "GET",
        url: "/api/location/locationfinder/locationsearch",
        data: {
            key: keyword, latitude: lat, longitude: longi, radius: mile
        }
    })
        .done(function (data) {
            console.log(data.LocationItemList);
            clearMarkers();
            updateHtml(data.LocationItemList, hideMap);
            copySet = JSON.parse(JSON.stringify(data.LocationItemList));
            addMarkers(map, data.LocationItemList);
            locations(data.LocationItemList);
            if (data.SearchMode === "state") {
                $('.choose-location').hide();
            } else {
                $('.choose-location').show();
            }
        });
}

$('.back-to-location').click(function () {
    var hostName = window.location.origin;
    var lastLocation = JSON.parse(localStorage.getItem("lastLocation"));
    lastLocation.isBackButtonClicked = true;
    localStorage.setItem("lastLocation", JSON.stringify(lastLocation));
    window.location.href = hostName + "/locations";
});

$("body").on('click', "a.mobile-link", function (e) {
    e.preventDefault();
    var cardTopPosition = $(window).scrollTop();
    var lastLocation = { location: $("#pac-input").val(), isBackButtonClicked: false, topPosition: cardTopPosition };
    localStorage.setItem("lastLocation", JSON.stringify(lastLocation));
    window.location.href = $(this).attr("href");
});

var geocoder = null;
if (typeof google === 'object' && typeof google.maps === 'object') {
    geocoder = new google.maps.Geocoder();
}


function closeAllLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != input) {
            x[i].parentNode.removeChild(x[i]);
        }
    }
}

function attachClickEvent(ele, item) {
    ele.addEventListener("click",
        function (e) {
            $('.choose-location').html("");
            $('.choose-location').html(selectbox);
            var lat, longi;
            input.value = this.getElementsByTagName("input")[0].value;
            geocoder.geocode({ 'address': input.value + ", USA" },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        lat = results[0].geometry.location.lat();
                        longi = results[0].geometry.location.lng();
                    }
                });

            setTimeout(function () {
                var lastLocation = { lat: lat, longi: longi };
                localStorage.setItem("locationLatLong", JSON.stringify(lastLocation));
                getLocationData(input.value, lat, longi, mile, true);
            },
                1000);

            closeAllLists();
        });
}

function autocomplete(inp, arr) {
    var currentFocus;

    currentFocus = -1;
    
    const debounce = (func, delay) => {
        let inDebounce;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        }
    }

    //on keyup, start the countdown
    $('#pac-input').on('keyup', debounce(function (e) {
        hitEnter = e.keyCode === 13 ? true : false;
        doneTyping(e);
    }, 1000));

    //user is finished typing.
    function doneTyping(e) {
        if (!hitEnter) {
            if (inp.value != "" && inp.value.length > 2) {
                var lat, longi;
                locationExist = false;
                var loc = objArray.filter((x) => {
                    if (x.toLowerCase().indexOf(inp.value.toLowerCase()) !== -1) {
                        return true;
                    }

                });
                if (loc.length <= 0) {
                    geocoder.geocode({ 'address': inp.value + ", USA" },
                        function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                lat = results[0].geometry.location.lat();
                                longi = results[0].geometry.location.lng();
                            }
                        });

                    setTimeout(function () {
                        if (lat !== undefined && longi != undefined) {
                            let autosuggestUrl = '/api/location/locationfinder/Getlocations?key=' +
                                inp.value +
                                '&latitude=' +
                                lat +
                                '&longitude=' +
                                longi;
                            $.ajax({
                                method: "GET",
                                dataType: 'json',
                                contentType: "application/json",
                                url: autosuggestUrl
                            })
                                .done(function (data) {
                                    var locationArray = [];
                                    var response = data;
                                    if (response) {
                                        $.each(response,
                                            function (key, value) {
                                                if ($.inArray(value, locationArray) < 0) {
                                                    locationArray.push(value);
                                                }
                                            });

                                        var a, b, i, val = inp.value;
                                        closeAllLists();
                                        if (!val) {
                                            return false;
                                        }
                                        a = document.createElement("DIV");
                                        a.setAttribute("id", this.id + "autocomplete-list");
                                        a.setAttribute("class", "autocomplete-items");
                                        inp.parentNode.appendChild(a);
                                        for (i = 0; i < locationArray.length; i++) {
                                            b = document.createElement("DIV");
                                            var word = locationArray[i];
                                            b.innerHTML =
                                                "<strong>" + word.substr(0, word.length) + "</strong>";
                                            b.innerHTML += word.substr(word.length);
                                            b.innerHTML += "<input type='hidden' value='" + word + "'>";
                                            var item = locationArray[i];
                                            attachClickEvent(b, item);
                                            a.appendChild(b);
                                        }
                                    } else {
                                        var a;
                                        a = document.createElement("DIV");
                                        a.setAttribute("id", this.id + "autocomplete-list");
                                        a.setAttribute("class", "autocomplete-items");
                                        input.parentNode.appendChild(a);
                                        var b = document.createElement("DIV");
                                        b.innerHTML += "<strong> Search for " +
                                            input.value +
                                            "</strong><input type='hidden' value='" +
                                            input.value +
                                            "'>";
                                        attachClickEvent(b, $('.bac'));
                                        a.appendChild(b);
                                    }
                                });
                        }
                    },
                        500);
                } else {
                    var a, b, i, val = inp.value;
                    closeAllLists();
                    if (!val) {
                        return false;
                    }
                    a = document.createElement("DIV");
                    a.setAttribute("id", this.id + "autocomplete-list");
                    a.setAttribute("class", "autocomplete-items");
                    inp.parentNode.appendChild(a);
                    for (i = 0; i < loc.length; i++) {
                        //if (objArray[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                        b = document.createElement("DIV");
                        var word = loc[i];
                        b.innerHTML = "<strong>" + word.substr(0, word.length) + "</strong>";
                        b.innerHTML += word.substr(word.length);
                        b.innerHTML += "<input type='hidden' value='" + word + "'>";
                        var item = loc[i];
                        attachClickEvent(b, item);
                        a.appendChild(b);
                        //}
                    }
                }

                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) x = x.getElementsByTagName("div");
                if (e.keyCode == 40) {
                    currentFocus++;
                    addActive(x);
                } else if (e.keyCode === 38) { //up
                    currentFocus--;
                    addActive(x);
                } else if (e.keyCode === 13) {
                    e.preventDefault();
                    if (currentFocus > -1) {
                        if (x) x[currentFocus].click();
                    }
                }
            }
        } else {
            geocoder.geocode({ 'address': inp.value + ", USA" },
                function (results, status) {
                    $('.choose-location').html("");
                    $('.choose-location').html(selectbox);
                    if (status === google.maps.GeocoderStatus.OK) {
                        var lat = results[0].geometry.location.lat();
                        var longi = results[0].geometry.location.lng();

                        if (lat !== undefined && longi != undefined) {
                            var lastLocation = { lat: lat, longi: longi };
                            localStorage.setItem("locationLatLong", JSON.stringify(lastLocation));
                            getLocationData(inp.value, lat, longi, mile, false);
                            closeAllLists();
                        }
                    }
                });
        }
    }

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}

function initMap() {
    map = new google.maps.Map(document.getElementById('map'), {
        center: {
            lat: 33.8373,
            lng: -84.4068
        },
        zoom: 6,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    setCurrentLocation(map);

    $("body").on('change', '#sl_miles',
        function () {
            clearMarkers();
            var locationLatlong = JSON.parse(localStorage.getItem("locationLatLong"));
            getLocationData($searchField.val(), locationLatlong.lat, locationLatlong.longi, $(this).val(), false);
        });
}

function setCurrentLocation(map) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            map.setCenter(pos);
        });
    }
}

function updateHtml(data, hideMap) {
    $('.location-data').addClass("show");
    $('.location-data').removeClass("hidden");

    if ((data != null || data === undefined) && data.length <= 0) {
        //if (hideMap) {
        //var a;
        //var inp = document.getElementById("pac-input");
        //a = document.createElement("DIV");
        //a.setAttribute("id", this.id + "autocomplete-list");
        //a.setAttribute("class", "autocomplete-items");
        //inp.parentNode.appendChild(a);


        //var b = document.createElement("DIV");
        //b.innerHTML += "<strong> Search for " + inp.value + "</strong><input type='hidden' value='" + inp.value + "'>";

        //attachClickEvent(b, $('.bac'));
        //a.appendChild(b);
        //$('.location-data').addClass("hidden");
        //$('.location-data').removeClass("show");
        //$('.map-head label').show();
        //}
    } else {
        //$('.map-head label').hide();
        $('.location-data').addClass("show");
        $('.location-data').removeClass("hidden");
    }
}

function clearMarkers() {
    markers.forEach(function (marker) {
        marker.setMap(null);
    });
    markers = [];
}

function addMarkers(map, places) {
    var bounds = new google.maps.LatLngBounds();
    if (places && places.length > 0) {
        for (let i = 0; i < places.length; i++) {
            var place = places[i];
            if (place.geometry && place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                place.location = new google.maps.LatLng(place.Latitude, place.Longitude);
                bounds.extend(place.location);
            }
            var icon = {
                url: '../Assets/Includes/REB/img/marker.png',
                size: new google.maps.Size(210, 160),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 32),
                scaledSize: new google.maps.Size(52.5, 65)
            };
            var locationInfowindow = new google.maps.InfoWindow({
                content: '<strong>' + places[i].LocationName + '</strong><br/>' + (places[i].Phone != null) ? places[i].Phone.replace("Phone Number&nbsp;", "") : "",
            });
            var marker = new google.maps.Marker({
                icon: icon,
                map: map,
                title: place.LocationName,
                position: place.location,
                infowindow: locationInfowindow
            });

            function hideAllInfoWindows(map) {
                markers.forEach(function (marker) {
                    marker.infowindow.close(map, marker);
                });
            }

            markers.push(marker);
            google.maps.event.addListener(marker, 'click', function () {
                hideAllInfoWindows(map);
                this.infowindow.open(map, this);
                $('.info-block').removeClass("active");
                var activeItem = $('.location-list-display').find('#location' + i);
                $(activeItem).addClass("active");
                var container = $('.location-list'),
                    scrollTo = $('.info-block.active');

                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
                });

                $('.info-block[data-index="' + i + '"]').addClass('active');
            });
        }
    }
    else {
        var locationLatlong = JSON.parse(localStorage.getItem("locationLatLong"));
        map.setCenter({
            lat: locationLatlong.lat,
            lng: locationLatlong.longi
        });
    }
    if (places && places.length !== 0) {
        map.fitBounds(bounds);
    }

    if (places && places.length < 2) {
        google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
            if (this.getZoom()) {
                this.setZoom(16);
            }
        });
    }
}

function locations(places) {
    $('#location-list-display').empty();
    if (places && places.length > 0) {
        for (var i = 0; i < places.length; i++) {
            var html = '<div class="info-block" id="location' + i + '" data-markerid="' + i + '">';
            html += '<div class="info-icon">';
            html += '<i class="fa fa-map-marker-alt" aria-hidden="true"></i>';
            html += '</div>';
            html += '<div class="info-info">';
            html += places[i].Address;
            html += ' </div>';
            html += '<div class="info-more">';
            html += '<p>' +
                places[i].DistanceInMiles +
                '<br/> <span style="position: relative; font-size: 50%; top: -10px; color: #999999;">from city center</span></p>';
            html += '<div class="extra-link"><a href="' + places[i].Url + '">More details +</a>';
            html += '</div></div><div class="clear"></div>';
            html += '<a class="mobile-link" href="' +
                places[i].Url +
                '"><span style="display: none;">' +
                places[i].LocationName +
                '</span></a>';
            $('#location-list-display').append(html);
        }
    } else {
        $('#location-list-display').append('<div class="info-block"><p>No Location Found</p></div>');
    }
}

//calculate miles
function calcDistance(lat1, lon1, lat2, lon2) {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;

    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
}

function filterMiles(data, miles) {
    var mile = miles;
    var result = data;
    if (!isNaN(mile) && data && data.length > 0) {

        result = data.filter(function (val) {
            return val.Distance <= mile;
        });
    }
    return result;
}

var suggestionsContainer;
var $this = $(this);

if ($searchField.length > 0) {
    $('.map-head form').submit(function () {
        return false;
    });
    autocomplete(input, objArray);
}
initSearch();
