/*jshint esversion: 6 */


function myMap() {
    if (typeof google === 'object' && typeof google.maps === 'object') {
        var mapElement = document.getElementById('googleMap');
        if (mapElement) {
            var latitudeValue = $("#latitudeValue").val();
            var longitudeValue = $("#longitudeValue").val();
            var location = new google.maps.LatLng(latitudeValue, longitudeValue);
            var map = new google.maps.Map(mapElement,
                {
                    center: location,
                    zoom: 12,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });
        }
        var icon = {
            url: '../Assets/Includes/REB/img/marker.png',
            size: new google.maps.Size(210, 160),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 32),
            scaledSize: new google.maps.Size(52.5, 65)
        };
        new google.maps.Marker({
            icon: icon,
            position: location,
            map: map
        });
    }
}
myMap();