/*jshint esversion: 6 */
$('.page-item.left-angle .page-link').on('click', function () {
    if ($('.pagination .page-item.active').prev().length > 0) {
        var pageLink = $('.pagination .page-item.active').prev().find('.page-link').attr('href');
        if (pageLink !== undefined) {
            window.location.href = window.location.protocol + "//" + window.location.host + pageLink;
        }
    }
});

$('.page-item.right-angle .page-link').on('click', function () {
    if ($('.pagination .page-item.active').next().length > 0) {
        var pageLink = $('.pagination .page-item.active').next().find('.page-link').attr('href');
        if (pageLink !== undefined) {
            window.location.href = window.location.protocol + "//" + window.location.host + pageLink;
        }
    }
});

$(".search_input input").autocomplete({
    minLength: 3,
    select: function (event, ui) {
        $('#txtQuery').val(ui.item.value);
        $("#lnkSearch").click();
    },
    source: function (request, response) {
        $.ajax({
            url: "/api/search/searchsuggestion/getsearchsuggestions?q=" + $(".search_input input").val(),
            dataType: "json",
            type: "GET",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                response(data);
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log(textStatus);
            }
        });
    }
});

$(".search .modal-body input").autocomplete({
    appendTo: '#modal-search-suggestions',
    minLength: 3,
    select: function (event, ui) {
        $(".search .modal-body input").val(ui.item.value);
        $(".search .modal-body button").click();
    },
    source: function (request, response) {
        $.ajax({
            url: "/api/search/searchsuggestion/getsearchsuggestions?q=" + $(".search .modal-body input").val(),
            dataType: "json",
            type: "GET",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                response(data);
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log(textStatus);
            }
        });
    }
});