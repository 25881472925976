var salected = $('.feature-grid-show-content').find('.btn');
salected.click(function () {
    var arrow_find = $(this).find('.fa');
    if (arrow_find.hasClass("fa-angle-down")) {
        arrow_find.addClass('fa-angle-up').removeClass('fa-angle-down');
    }
    else {
        arrow_find.addClass('fa-angle-down').removeClass('fa-angle-up');
    }
});
//Advisor Component accordions arrow button move
var salected_advisor = $('.heading-coll').find('.btn');
salected_advisor.click(function () {
    var arrow_find = $(this).find('.fa');
    if (arrow_find.hasClass("fa-angle-down")) {
        arrow_find.addClass('fa-angle-up').removeClass('fa-angle-down');
    }
    else {
        arrow_find.addClass('fa-angle-down').removeClass('fa-angle-up');
    }
});