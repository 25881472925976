(function () {

    var tabnavactive = {


        init : function () {
            //$(".tabNav > li:first-child > a").addClass("active"); 
            //$(".nav-pills:not(.ter-nav) > a:first-child").addClass("active");
            //var find_pills = $(".nav-pills a")[0];
            //$(find_pills).addClass("active");
            $(".tab-content > div:first-child").addClass("active");
            }   
      
    }

    module.exports = tabnavactive;

})();